import { Component } from 'react'; 
import { NavLink, Link } from 'react-router-dom';
import { ContentfulContext } from "../../contexts/context";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';

import headerLogo from '../../images/logo.png';
import MetaPixel from '../MetaPixel/MetaPixel';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

class Header extends Component {

    state = {
        isSearchOpen: false,
        isMenuOpen: true
    }

    handleSearchToggle = () => {
        this.setState({ isSearchOpen: !this.state.isSearchOpen });
    }

    handleMenuToggle = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen });
        this.toggleClass();
    }

    toggleClass = () => {
        if (this.state.isMenuOpen) {
            document.querySelector('html').classList.add('rfb-menu-open');
        }else {
            document.querySelector('html').classList.remove('rfb-menu-open');
        }
    }


    static contextType = ContentfulContext;

    render() {
        const { contentfulItems } = this.context;

        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };

        const detailItems = [];
        
        if(contentfulItems) {
            for (let item of Object.keys(contentfulItems)) {
                if(contentfulItems[item].fields.showInMenu) {
                    let detail = {
                        slug: contentfulItems[item].fields.slug,
                        title: contentfulItems[item].fields.page,
                        order: contentfulItems[item].fields.order
                    }
                    detailItems.push(detail);
                }
            }
        }

        detailItems.sort((a, b) => a.order - b.order);

        let pathname = window.location.pathname;
        
        const isEnglish = pathname.includes('/en') ? true : false;

        let currentPathname = !pathname.includes('/en') ? pathname : pathname.replace("/en", "");  
               
        return(
            <>
                <MetaPixel />
                <Observer {...options}>
                    <div className={`rfb-header rfb-p-40 rfb-p-md-30 rfb-w-100 ${ this.state.isSearchOpen ? `rfb-header--open-search` : null} } `}>
                        <div className="rfb-container">
                            <div className="rfb-flex rfb-flex--items-center rfb-flex--space-between">
                                <Link to={ isEnglish ? `/en/home/` : `/home/`} className="rfb-header__logo gsap-timeline">
                                    <picture className="rfb-d-block">
                                        <source srcSet={ headerLogo } />
                                    </picture>
                                </Link>
                                <div className="rfb-header__nav rfb-flex rfb-flex--items-center rfb-zindex-2 rfb-relative rfb-flex-md--direction-column rfb-flex-md--items-start">
                                    <ul className="rfb-header__nav__list rfb-flex rfb-flex--items-center">
                                    {
                                        detailItems.map( (detail, index) => { 
                                        return    <li key={ index } className="rfb-header__nav__item gsap-timeline">
                                                        <NavLink exact to={ isEnglish ? `/en/${ detail.slug }/` : `/${ detail.slug }/`} className="rfb-header__nav__link" onClick={ this.handleMenuToggle }>
                                                            { detail.title }
                                                        </NavLink>
                                                    </li>
                                        })
                                    }                             
                                        <li className="rfb-header__nav__item rfb-header__nav__item--lang rfb-flex gsap-timeline">
                                            <a className={ isEnglish ? 'rfb-header__nav__link rfb-header__nav__link--lang' : 'rfb-header__nav__link rfb-header__nav__link--lang rfb-header__nav__link--lang--active' } href={'' + currentPathname}>AL</a>
                                            <a className={ isEnglish ? 'rfb-header__nav__link rfb-header__nav__link--lang rfb-header__nav__link--lang--active' : 'rfb-header__nav__link rfb-header__nav__link--lang' } href={'/en' + currentPathname}>EN</a>
                                        </li>
                                        <li className="rfb-flex rfb-flex--items-center rfb-header__nav__item rfb-header__nav__item--search gsap-timeline">
                                            <svg className="rfb-relative rfb-zindex-2 rfb-header__search-icon" width="25.314" height="25.314" viewBox="0 0 25.314 25.314" onClick={ this.handleSearchToggle }>
                                                <path fill="#000" d="M18.577,16.342a10.322,10.322,0,1,0-2.237,2.237l6.737,6.737,2.237-2.237-6.737-6.737Zm-8.293,1.064a7.12,7.12,0,1,1,7.12-7.12,7.127,7.127,0,0,1-7.12,7.12Z" transform="translate(0 -0.002)"/>
                                            </svg>
                                            <svg className="rfb-absolute rfb-zindex-2 rfb-header__close-icon" height="18px" viewBox="0 0 329.26933 329" width="18px" onClick={ this.handleSearchToggle }>
                                                <path fill="#8F8F8F" d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
                                            </svg>
                                        </li>
                                    </ul>
                                    <form role="search" method="get" action="/search" className="rfb-header__form">
                                        <input type="search" className="rfb-header__input" autoComplete="nope" name="keyword" id="keyword" placeholder="Search" />
                                    </form>
                                </div>
                                <div className="rfb-header__ham rfb-ml-auto rfb-d-lg-none" onClick={ this.handleMenuToggle }>
                                    <span className="rfb-header__ham__line"></span> 
                                    <span className="rfb-header__ham__line"></span>
                                    <span className="rfb-header__ham__line"></span>
                                    <span className="rfb-header__ham__line"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Observer>
            </>
        )
    }
}

export default Header;