import { Component } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, loadImage } from '../../utility/timeline';

const handleIntersection = (event, unobserve) => {
    if(event.isIntersecting) {
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}
class TAIFBox extends Component {
    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
        const {inputLabel, formCheckboxes, children} = this.props;
        const isEnglish = window.location.pathname.includes('/en') ? true : false;
        return(
            <Observer {...options}>
                <div className="rfb-taif__box-responsive rfb-absolute rfb-sm-relative rfb-t-0 rfb-b-0 rfb-w-100 rfb-taif-box--min-height rfb-mb-sm-32">
                    <div className="rfb-container rfb-relative rfb-flex rfb-window-height rfb-flex--items-center rfb-flex--justify-end rfb-flex-sm--justify-center rfb-taif-box">
                        <form action="/contact" className="rfb-taif-absolute-box rfb-w-45 rfb-w-sm-100 rfb-pt-24 rfb-taif-box-border gsap-timeline">
                            <div className="rfb-pl-40 rfb-pr-40 rfb-pb-24">
                                <h3 className="rfb-taif-absolute-box-title rfb-font-primary-bold rfb-mb-24 rfb-ls-16-negative">{ children }</h3>
                                <span className="rfb-taif-absolute-box-subtitle rfb-mb-4 rfb-ls-16-negative rfb-font-primary-medium">{ inputLabel }</span>
                                <div className="rfb-relative rfb-mb-32 rfb-pb-8 rfb-flex rfb-flex--items-center rfb-flex--space-between rfb-border-b">
                                    <input type="text" className="rfb-font-primary rfb-font-primary-medium rfb-w-100 rfb-ls-16-negative rfb-taif-price rfb-ls-16-negative" name={`${children}_price`} placeholder={(isEnglish) ? `Price` : `Cmimi`} />
                                    <span className="rfb-absolute rfb-r-0 rfb-font-primary-bold rfb-taif-price-symbol">€</span>
                                </div>
                                {
                                    formCheckboxes.map(({fields}, index) => {
                                        return <div key={ index } className="rfb-mb-20">
                                                    <input type="checkbox" name={`${children}_selected`} id={`${children}_${fields.title}`} value={`${fields.title}`} className="rfb-absolute rfb-taif-option-input" />
                                                    <label htmlFor={`${children}_${fields.title}`} className="rfb-relative rfb-taif-label rfb-ls-16-negative">
                                                        { fields.title }
                                                    </label>
                                                </div>
                                    })
                                }
                            </div>
                            <div className="rfb-relative rfb-taif-button-submit">
                                <input id={`${children.replace(/ /g,"")}_continue`} type="submit" hidden />
                                <label className="rfb-button--primary rfb-taif-button-continue rfb-font-primary-medium rfb-w-100 rfb-flex rfb-flex--items-center rfb-flex--space-between rfb-pl-40 rfb-pr-40 rfb-ls-16-negative" htmlFor={`${children.replace(/ /g,"")}_continue`}>
                                    {(isEnglish) ? `Continue` : `Vazhdo`}
                                    <span className="rfb-flex rfb-taif-button-svg">
                                        <svg width="40.345" height="20.712" viewBox="0 0 40.345 20.712">
                                            <g id="back" transform="translate(40.345 20.712) rotate(180)">
                                                <path d="M40.345,9.061H4.958l7.23-7.23L10.356,0,0,10.356,10.356,20.712l1.832-1.832-7.23-7.23H40.345Z"/>
                                            </g>
                                        </svg>
                                    </span>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </Observer>
        );
    }
}

export default TAIFBox;